const ENVIRONMENT_ENUM = {
  TEST: "test",
  QA: "qa",
  PRODUCTION: "production"
};

const COUNTRY_ENUM = {
  BE: "BE",
  DK: "DK",
  NL: "NL"
};

const INSTANCE_ENUM = {
  SNOW_BELGIUM: "SNOW_BELGIUM",
  SNOW_GROUP: "SNOW_GROUP"
};

const OPERATOR_ENUM = {
  LESS_THAN_EQUALS: "LESS_THAN_EQUALS",
  LESS_THAN: "LESS_THAN",
  GREATER_THAN: "GREATER_THAN",
  GREATER_THAN_EQUALS: "GREATER_THAN_EQUALS",
  BETWEEN: "BETWEEN",
  IN: "IN",
  NOT_IN: "NOT_IN",
  EQUALS: "EQUALS"
};

const STATE_FILTER_ENUM = {
  ASSESS: "ASSESS",
  AUTHORIZE: "AUTHORIZE",
  CANCELED: "CANCELED",
  CLOSED: "CLOSED",
  FIX_IN_PROGRESS: "FIX_IN_PROGRESS",
  IMPLEMENT: "IMPLEMENT",
  IN_PROGRESS: "IN_PROGRESS",
  NEW: "NEW",
  ON_HOLD: "ON_HOLD",
  RESOLVED: "RESOLVED",
  REVIEW: "REVIEW",
  ROOT_CAUSE_ANALYSIS: "ROOT_CAUSE_ANALYSIS",
  SCHEDULED: "SCHEDULED"
};

const CHANGE_STATE_FILTER_ENUM = {
  NEW: "NEW",
  ASSESS: "ASSESS",
  AUTHORIZE: "AUTHORIZE",
  SCHEDULED: "SCHEDULED",
  IMPLEMENT: "IMPLEMENT",
  REVIEW: "REVIEW",
  CLOSED: "CLOSED",
  CANCELED: "CANCELED"
};

const PROBLEM_STATE_FILTER_ENUM = {
  NEW: "NEW",
  ASSESS: "ASSESS",
  ROOT_CAUSE_ANALYSIS: "ROOT_CAUSE_ANALYSIS",
  FIX_IN_PROGRESS: "FIX_IN_PROGRESS",
  RESOLVED: "RESOLVED",
  CLOSED: "CLOSED"
};

const INCIDENT_STATE_FILTER_ENUM = {
  NEW: "NEW",
  IN_PROGRESS: "IN_PROGRESS",
  ON_HOLD: "ON_HOLD",
  RESOLVED: "RESOLVED",
  CLOSED: "CLOSED",
  CANCELED: "CANCELED"
};

const CATEGORY_FILTERS_ENUM = {
  CHANGE_REQUEST: "CHANGE_REQUEST",
  INCIDENT: "INCIDENT",
  PROBLEM: "PROBLEM",
  SECURITY_INCIDENT: "SECURITY_INCIDENT",
  SERVICE_REQUEST: "SERVICE_REQUEST"
};

const CLOUD_PROVIDER_ENUM = {
  AWS: "AWS",
  AZURE: "AZURE"
};

const MSC_CUSTOMER_STATUS_ENUM = {
  BLACKLISTED: "Blacklisted",
  ACTIVE: "Active"
};

const MSC_CUSTOMER_ACTION_ENUM = {
  ADD: "ADD",
  DELETE: "DELETE"
};

const MSC_RESOURCE_ACTION_ENUM = {
  ADD: "ADD",
  DELETE: "DELETE"
};

const MSC_SCALE_ENUM = {
  GLOBAL: "GLOBAL",
  COUNTRY: "COUNTRY",
  CUSTOMER: "CUSTOMER"
};

const MSC_SERVICE_LEVEL_ENUM = {
  BUSINESS_CRITICAL: "BC",
  MISSION_CRITICAL: "MC",
  NON_CRITICAL: "NC"
};

const MSC_TAG_ENUM = {
  BILLING_CODE: "billingCode",
  CONTRACT_NUMBER: "contractNumber",
  ENVIRONMENT: "environment",
  SERVICE_LEVEL: "serviceLevel"
};

export {
  ENVIRONMENT_ENUM,
  COUNTRY_ENUM,
  INSTANCE_ENUM,
  INCIDENT_STATE_FILTER_ENUM,
  CHANGE_STATE_FILTER_ENUM,
  CATEGORY_FILTERS_ENUM,
  CLOUD_PROVIDER_ENUM,
  MSC_CUSTOMER_STATUS_ENUM,
  MSC_CUSTOMER_ACTION_ENUM,
  MSC_RESOURCE_ACTION_ENUM,
  MSC_SCALE_ENUM,
  MSC_SERVICE_LEVEL_ENUM,
  MSC_TAG_ENUM,
  OPERATOR_ENUM,
  PROBLEM_STATE_FILTER_ENUM,
  STATE_FILTER_ENUM
};
