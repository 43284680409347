import gql from "graphql-tag";

const LIST_MONTHS = gql`
  query ListMonths($filters: AvailabilityReportFilters) {
    reports {
      availability {
        listMonth(filters: $filters) {
          result {
            accountNumber
            asset
            serviceId
            slaId
            slaName
            uptime
            hostName
            months {
              date
              uptime
              asset
            }
          }
          metadata {
            conversationId
            message
            statusCode
            statusText
          }
        }
      }
    }
  }
`;

const LIST_DAYS = gql`
  query ListDay($filters: AvailabilityReportFilters) {
    reports {
      availability {
        listDay(filters: $filters) {
          result {
            accountNumber
            asset
            date
            hostName
            identifier
            serviceId
            slaId
            slaName
            uptime
          }
          metadata {
            conversationId
            message
            statusCode
            statusText
          }
        }
      }
    }
  }
`;

const GET_EXPORT = gql`
  query GetExport($filters: AvailabilityReportExportFilters) {
    reports {
      availability {
        export(filters: $filters) {
          result {
            uptime {
              value
            }
            months {
              value
            }
            asset
            slaName
            hostName
          }
          metadata {
            conversationId
            message
            statusCode
            statusText
          }
        }
      }
    }
  }
`;

const UPDATE_UPTIME = gql`
  mutation UpdateUptime($input: AvailabilityReportItemInput!) {
    availabilityReportUpdate(input: $input) {
      metadata {
        conversationId
        message
        statusCode
        statusText
      }
    }
  }
`;

const RECALCULATE_MONTH_UPTIME = gql`
  mutation RecalculateMonthUptime($input: AvailabilityReportInput!) {
    availabilityReportRecalculate(input: $input) {
      metadata {
        conversationId
        message
        statusCode
        statusText
      }
    }
  }
`;

const IGNORE_DOWNTIMES = gql`
  mutation IgnoreDowntimes($input: AvailabilityReportInput!) {
    availabilityReportIgnore(input: $input) {
      metadata {
        conversationId
        message
        statusCode
        statusText
      }
    }
  }
`;

const LIST_ACCOUNTS = gql`
  query ListAccounts {
    customers {
      accounts(
        filters: {
          provider: { value: BE }
          active: { operator: EQUALS, value: true }
        }
        pagination: { limit: -1 }
      ) {
        totalCount
        result {
          accountNumber
          name
        }
      }
    }
  }
`;

export {
  GET_EXPORT,
  IGNORE_DOWNTIMES,
  LIST_ACCOUNTS,
  LIST_DAYS,
  LIST_MONTHS,
  RECALCULATE_MONTH_UPTIME,
  UPDATE_UPTIME
};
