/* eslint-disable max-len */
var PizZip = require("pizzip");
var PizZipUtils = require("pizzip/utils/index.js");
var Docxtemplater = require("docxtemplater");
var ImageModule = require("docxtemplater-image-module");
var expressions = require("angular-expressions");
var assign = require("lodash/assign");
import { saveAs } from "file-saver";

const DocxGeneratorService = {
  loadFile(url, callback) {
    PizZipUtils.getBinaryContent(url, callback);
  },

  base64DataUriToArrayBuffer(dataUri) {
    const base64Regex = /^data:image\/(png|jpg|svg|svg\+xml);base64,/;
    if (!base64Regex.test(dataUri)) {
      return false;
    }
    const stringBase64 = dataUri.replace(base64Regex, "");
    let binaryString;
    if (typeof window !== "undefined") {
      binaryString = window.atob(stringBase64);
    } else {
      binaryString = Buffer.from(stringBase64, "base64").toString("binary");
    }
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes.buffer;
  },
  generate({ title, templateUrl, data }) {
    title = title + ".docx";
    this.loadFile(templateUrl, function (error, content) {
      if (error) {
        throw error;
      }

      function angularParser(tag) {
        if (tag === ".") {
          return {
            get: function (s) {
              return s;
            }
          };
        }
        const expr = expressions.compile(
          tag.replace(/(’|‘)/g, "'").replace(/(“|”)/g, '"')
        );
        return {
          get: function (scope, context) {
            let obj = {};
            const scopeList = context.scopeList;
            const num = context.num;
            for (let i = 0, len = num + 1; i < len; i++) {
              obj = assign(obj, scopeList[i]);
            }
            return expr(scope, obj);
          }
        };
      }

      expressions.filters.lower = function (input) {
        // This condition should be used to make sure that if your input is
        // undefined, your output will be undefined as well and will not
        // throw an error
        if (!input) return input;
        return input.toLowerCase();
      };
      // Image module
      const imageOpts = {
        getImage(tag) {
          return DocxGeneratorService.base64DataUriToArrayBuffer(tag);
        },
        getSize() {
          return [985, 250];
        }
      };
      var zip = new PizZip(content);
      var doc;
      try {
        doc = new Docxtemplater(zip, {
          parser: angularParser,
          nullGetter() {
            return "";
          },
          modules: [new ImageModule(imageOpts)]
        });

        doc.setData(data);
        doc.render();
        var out = doc.getZip().generate({
          type: "blob",
          mimeType:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        }); //Output the document using Data-URI

        saveAs(out, title);
      } catch (error) {
        // Catch compilation errors (errors caused by the compilation of the template : misplaced tags)
        if (error.properties && error.properties.errors instanceof Array) {
          const errorMessages = error.properties.errors
            .map(function (error) {
              return error.properties.explanation;
            })
            .join("\n");

          console.log("errorMessages", errorMessages);
          // errorMessages is a humanly readable message looking like this :
          // 'The tag beginning with "foobar" is unopened'
        }

        throw error;
      }
    });
  }
};

export default DocxGeneratorService;
