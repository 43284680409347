import {
  GET_EXPORT,
  IGNORE_DOWNTIMES,
  LIST_ACCOUNTS,
  LIST_DAYS,
  LIST_MONTHS,
  RECALCULATE_MONTH_UPTIME,
  UPDATE_UPTIME
} from "@/view/pages/be/reporting/availability_report/graphql";
import dayjs from "dayjs";
import { DATE_FORMAT } from "@/core/constants/time";
import DocxGenerator from "@/core/services/docxgenerator.service";
import { OPERATOR_ENUM } from "@/core/constants";
import { SET_BREADCRUMB } from "@/store/common/breadcrumbs.module";

export default {
  apollo: {
    accounts() {
      return {
        client: "connectClient",
        query: LIST_ACCOUNTS,
        update(data) {
          if (data.customers.accounts) return data.customers.accounts.result;
        }
      };
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "BE" },
      { title: "Reporting" },
      { title: "Availability Report" }
    ]);
  },
  data() {
    return {
      menu: false,
      selectedDate: [
        dayjs().subtract(7, "month").format(DATE_FORMAT.MONTH),
        dayjs().subtract(1, "month").format(DATE_FORMAT.MONTH)
      ],
      selectedAccountNumber: "ACC-0000012362",
      isLoading: false,
      assetTable: {
        headers: [
          { text: "Asset", value: "asset" },
          { text: "Host Name", value: "hostName" },
          { text: "Account Number", value: "accountNumber" },
          { text: "SLA", value: "slaName" },
          { text: "Uptime", value: "uptime" }
        ],
        data: []
      },
      monthTable: {
        headers: [
          { text: "Date", value: "date" },
          { text: "Uptime", value: "uptime" }
        ]
      },
      uptimeTable: {
        headers: [
          { text: "Date", value: "date" },
          { text: "Uptime", value: "uptime" }
        ],
        data: []
      },
      rules: {
        between0and100: v => -1 <= v >= 100 || "Value must be between 0 and 100"
      }
    };
  },
  methods: {
    dateChanged(dates) {
      let dateFrom = dayjs(dates[0]);
      let dateTo = dayjs(dates[1]);
      if (dateFrom.isAfter(dateTo)) {
        this.selectedDate = [
          dateTo.format(DATE_FORMAT.MONTH),
          dateFrom.format(DATE_FORMAT.MONTH)
        ];
      }
    },
    async loadAssets() {
      this.isLoading = true;
      this.assetTable.data = await this.$apollo
        .query({
          client: "connectClient",
          query: LIST_MONTHS,
          variables: {
            filters: {
              accountNumber: { value: [this.selectedAccountNumber] },
              date: {
                value: [
                  dayjs(this.selectedDate[0]).format(DATE_FORMAT.DAY),
                  dayjs(this.selectedDate[1])
                    .add(1, "month")
                    .format(DATE_FORMAT.DAY)
                ],
                operator: OPERATOR_ENUM.BETWEEN
              }
            }
          }
        })
        .then(response => {
          return response.data.reports.availability.listMonth.result;
        });
      this.isLoading = false;
    },
    async itemExpand({ item }) {
      this.isLoading = true;
      this.uptimeTable.data = [];
      const month = item.date;
      const asset = item.asset;
      let data = [];
      data = await this.$apollo
        .query({
          client: "connectClient",
          query: LIST_DAYS,
          variables: {
            filters: {
              accountNumber: { value: [this.selectedAccountNumber] },
              asset: { value: [asset] },
              date: {
                value: [dayjs(month).format(DATE_FORMAT.DAY)]
              }
            }
          }
        })
        .then(response => {
          return response.data.reports.availability.listDay.result;
        });
      this.uptimeTable.data = data;
      this.isLoading = false;
    },
    async save(item) {
      this.isLoading = true;
      await this.$apollo.mutate({
        client: "connectClient",
        mutation: UPDATE_UPTIME,
        variables: { input: item }
      });
      await this.$apollo.mutate({
        client: "connectClient",
        mutation: RECALCULATE_MONTH_UPTIME,
        variables: {
          input: {
            accountNumber: item.accountNumber,
            asset: [item.asset],
            date: dayjs(item.date).format(DATE_FORMAT.MONTH)
          }
        }
      });
      await this.loadAssets();
      this.isLoading = false;
    },
    async ignoreAllDowntimes() {
      this.isLoading = true;
      let dateFrom = dayjs(this.selectedDate[0]);
      let dateTo = dayjs(this.selectedDate[1]);

      let promises = [];

      while (dateFrom <= dateTo) {
        promises.push(
          this.$apollo.mutate({
            client: "connectClient",
            mutation: IGNORE_DOWNTIMES,
            variables: {
              input: {
                accountNumber: this.selectedAccountNumber,
                date: dateFrom.format(DATE_FORMAT.MONTH)
              }
            }
          })
        );
        dateFrom = dateFrom.add(1, "month");
      }
      await Promise.all(promises);
      promises = [];
      dateFrom = dayjs(this.selectedDate[0]);
      while (dateFrom <= dateTo) {
        promises.push(
          this.$apollo.mutate({
            client: "connectClient",
            mutation: RECALCULATE_MONTH_UPTIME,
            variables: {
              input: {
                accountNumber: this.selectedAccountNumber,
                date: dateFrom.format(DATE_FORMAT.MONTH)
              }
            }
          })
        );
        dateFrom = dateFrom.add(1, "month");
      }
      await Promise.all(promises);
      await this.loadAssets();
      this.isLoading = false;
    },
    async saveToWord() {
      const exportReport = await this.$apollo
        .query({
          client: "connectClient",
          query: GET_EXPORT,
          variables: {
            filters: {
              accountNumber: { value: [this.selectedAccountNumber] }
            }
          }
        })
        .then(response => response.data.reports.availability.export.result);
      const accountName = this.accounts.find(
        account => account.accountNumber === this.selectedAccountNumber
      )?.name;
      const exportData = {
        information: {
          date: dayjs().format(DATE_FORMAT.DAY),
          dateFrom: this.selectedDate[0],
          dateTo: this.selectedDate[1],
          accountNumber: this.selectedAccountNumber,
          accountName: accountName
        },
        reports: exportReport
      };
      const generate = {
        title: `Availability_Report_${this.selectedAccountNumber}`,
        templateUrl: "/templates/availability/template.docx",
        data: exportData
      };

      DocxGenerator.generate(generate);
    }
  }
};
