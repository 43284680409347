const DATE_FORMAT = {
  YEAR: "YYYY",
  MONTH: "YYYY-MM",
  DAY: "YYYY-MM-DD"
};

const DATETIME_FORMAT = {
  HOUR: "YYYY-MM-DD HH",
  MINUTE: "YYYY-MM-DD HH:mm",
  SECOND: "YYYY-MM-DD HH:mm:ss",
  OFFSET_UTC: "YYYY-MM-DD HH:mm:ssZ",
  ISO: "YYYY-MM-DDTHH:mm:ss.SSSZ"
};

const TIME_FORMAT = {
  HOUR: "HH",
  MINUTE: "HH:mm",
  SECOND: "HH:mm:ss"
};

export { DATE_FORMAT, DATETIME_FORMAT, TIME_FORMAT };
